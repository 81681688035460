import { Box, Container } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { usePrefsStore } from '../states/usePrefsStore'

const CatDesc = () => {
  const [searchParams] = useSearchParams()
  console.log('searchParams', searchParams.get('catKey'))

  const cats = usePrefsStore(state => state.catalog.categories)
  console.log('state.catalog.categories', cats[searchParams.get('catKey')])
  const cat = cats[searchParams.get('catKey')]

  return (
    <Container w='full' bg='gray.100' p='4'>
      <Box>Name: {cat.name}</Box>
      <Box>Description: {cat.desc}</Box>
      <Box>Flavours: {cat.flavours}</Box>
      <Box>Options: </Box>
      <Box>Image: {`${cat.key}_cat.png`}</Box>
    </Container>
  )
}

export default CatDesc