import axios from 'axios'
import { BASE_URL } from '../configs/configs'
import { immerSetPrefs, getPrefs } from './usePrefsStore'
import { getVersions, immerSetVersions } from './useVersionsStore'

const url = `${BASE_URL}/cncapp/app-data`
const urlVersion = `${BASE_URL}/cncapp/app-versions`

// Actions defs
// fetch version
export const fetchVersion = async () => {
  const { versions } = getVersions()
  console.log('CURRENT versions: ', versions)

  axios.get(urlVersion)
    .then(({ data }) => {
      const newvs = JSON.parse(data.versions)
      console.log('NEW versions: ', newvs)
      const isNewData = newvs.dataVersion && newvs.dataVersion !== versions.dataVersion
      const isNewApp = newvs.appVersion && newvs.appVersion !== versions.appVersion
      if (isNewData || isNewApp) {
        console.log('isNewData || isNewApp -> Update version, set fetched=false ', versions)
        immerSetVersions(state => {
          state.versions = { ...newvs }
        })
        immerSetPrefs(state => {
          state.fetched = false
        })
      }
    })
    .then(() => {
      if (!getPrefs().fetched) {
        fetchAppData()
      }
    })
    .catch(console.log)
}

// fetch initial app data, save to localStorage
export const fetchAppData = async () => {
  console.log('Fetching preferences...')
  axios.get(url)
    .then(res => {
      const { data: { catalog, locations, statuses } } = res
      immerSetPrefs(state => {
        if (catalog) {
          const jsonCatalog = JSON.parse(catalog)
          if (jsonCatalog.categories && Object.keys(jsonCatalog.categories).length > 0) {
            state.fetched = true
            state.catalog = jsonCatalog

            state.locations = JSON.parse(locations)

            const stats = JSON.parse(statuses)
            state.statuses = stats
            state.statusNames = Object.keys(stats).reduce((acc, key) => {
              return { ...acc, [stats[key]]: key }
            }, {})
          }
        }
      })
    })
    .catch(console.log)
}

export const getLocation = locId => {
  const locations = getPrefs().locations
  return locations.find(loc => loc.locId === locId)
}

export const getClosedDates = locId => {
  const location = getLocation(locId)
  return location && location.closedDates
}

