import { v4 as uuidv4 } from 'uuid'
import { immerSet } from './useStore'

export const cartReset = () => immerSet(state => { state.cart = [] })

export const cartAddItem = product => immerSet(state => { state.cart.unshift(createItem(product, 1)) })

export const isInCart = (cart, productId) => {
  return cart.find(item => item.productId === productId) ? true : false
}

export const qtyInCart = (cart, productId) => {
  return cart.reduce((acc, item) => {
    return item.productId === productId ? acc + item.qty : acc
  }, 0)
}

export const cartCopyItem = itemId => immerSet(state => {
  const idx = state.cart.findIndex(i => i.itemId === itemId)
  if (idx > -1) {
    const copied = fromItem(state.cart[idx], 1)
    state.cart.splice(idx, 0, copied)
  }
})

export const cartRemItem = itemId => immerSet(state => {
  const idx = state.cart.findIndex(i => i.itemId === itemId)
  idx > -1 && state.cart.splice(idx, 1)
})

export const cartIncQty = itemId => immerSet(state => {
  const item = state.cart.find(i => i.itemId === itemId)
  if (item) {
    item.qty = item.qty + 1
  }
})

export const cartDecQty = itemId => immerSet(state => {
  const idx = state.cart.findIndex(i => i.itemId === itemId)
  if (idx > -1) {
    const item = state.cart[idx]
    if (item.qty > 1) {
      item.qty = item.qty - 1
    } else {
      state.cart.splice(idx, 1) // remove if zero qty
    }
  }
})

export const cartChangeExtra = itemId => selectedExtra => immerSet(state => {
  const item = state.cart.find(i => i.itemId === itemId)
  if (item) {
    item.selectedExtra = selectedExtra
  }
})

export const cartChangeOptions = itemId => selectedOptions => immerSet(state => {
  const item = state.cart.find(i => i.itemId === itemId)
  if (item) {
    item.selectedOptions = selectedOptions
  }
})

// construct standard options
export const selectedStdOptions = (options) =>
  options
  &&
  Object.keys(options)
    .reduce((acc, key) => ({
      ...acc,
      [key]: options[key].map(optionAugmented)
    }),
      {}
    )

// utils
export const calTotal = cart => {
  const cartTotal = cart.reduce((acc, i) => {
    const amount = i.unitAmount + (i.selectedExtra ? i.selectedExtra.unitAmount : 0)
    return acc + amount * i.qty
  }, 0)
  return (cartTotal / 100).toFixed(2)
}

export const selectedExtraNo = cart => {
  return cart.reduce((acc, item) => {
    return item.selectedExtra ? acc + item.qty : acc
  }, 0)
}

export const itemNo = cart => {
  return cart.reduce((acc, item) => {
    return acc + item.qty
  }, 0)
}

export const itemNoText = cart => {
  if (!cart || cart.length === 0) {
    return ''
  }
  const no = itemNo(cart)
  return no === 1 ? `${no} item` : `${no} items`
}

export const cartReorder = cart => immerSet(state => { state.cart = [...cart] })

// Helpers
const createItem = (product, qty) => {
  const itemId = uuidv4()
  const { key, productId, priceId, unitAmount, gst, price, name, unitLabel, options, extras } = product

  // default: standard options
  const selectedOptions = selectedStdOptions(options)
  return {
    itemId,
    key,
    productId,
    name,
    price, // text: toFixed(2)
    unitLabel,
    options, // array of arrays: predefined available options
    selectedOptions, // selected options
    extras, // array of keys: predefined available  extras
    selectedExtra: undefined, // selected extra {key, priceId, name, unitLabel, unitAmount, price, qty(always is 1)}
    priceId,
    gst,
    unitAmount, // number: cents
    qty
  }
}

const fromItem = (item, qty) => {
  const itemId = uuidv4()
  return { ...item, qty, itemId }
}

const optionAugmented = option => ({ ...option, checked: option.std, enable: option.enable })