import { Button, Container, GridItem, HStack, SimpleGrid } from '@chakra-ui/react'
import useAuth from '../services/useAuth'
import { usePrefsStore } from '../states/usePrefsStore'
import { useDeviceCodesList, useSquareDevicesCodesCreate } from '../states/useRemoteStates'
import { useNavigate } from 'react-router-dom'
import useSquare from '../services/useSquare'

const SquareDeviceCodes = () => {
  const { auth } = useAuth()
  const { setSquare } = useSquare()

  const navigate = useNavigate()

  const locations = usePrefsStore(state => state.locations)
  const { squareLocationId, locId } = locations?.find(loc => loc.locId === auth.location)
  const { data: codes } = useDeviceCodesList(squareLocationId)
  console.log('useListDeviceCodes data', codes)

  const createDeviceCode = useSquareDevicesCodesCreate()

  const handleCreateNewCode = () => {
    createDeviceCode.mutate({ squareLocId: squareLocationId, name: `Counter Test ${Date.now()}` }, {
      onSuccess: ({ data }) => {
        console.log('createDeviceCode data', data)
      },
      onError: err => {
        console.log('createDeviceCode error', err.message)
      }
    })
  }

  const onSelectDevice = (squareDeviceId, squareCounterName, locId) => e => {
    // setSquare((prev) => ({ ...prev, squareDeviceId, squareCounterName }))
    if (process.env.NODE_ENV === 'production') {
      setSquare((prev) => ({ ...prev, squareDeviceId, squareCounterName, locId }))
    }
    else {
      setSquare(prev => ({
        ...prev,
        squareDeviceId: '9fa747a2-25ff-48ee-b078-04381f7c828f',
        squareCounterName: 'Counter TEST',
        locId
      }))
    }
    navigate('/') // present menu for ordering
  }

  return (
    <Container>
      <ul>
        {Array.isArray(codes) && codes.map(({ name, device_id, code, status, paired_at }) => {
          return (<li key={code}>
            <SimpleGrid columns={6} columnGap={3} rowGap={2} w="full">
              <GridItem>{name}</GridItem>
              <GridItem>{device_id}</GridItem>
              <GridItem>{code}</GridItem>
              <GridItem>{status}</GridItem>
              <GridItem>{paired_at || 'N/A'}</GridItem>
              {status === 'UNPAIRED' && <GridItem><Button>Pair</Button></GridItem>}
              {status === 'PAIRED' && <GridItem><Button onClick={onSelectDevice(device_id, name, locId)}>Select</Button></GridItem>}
            </SimpleGrid>
          </li>)
        })}
      </ul>
      <HStack w='full' justify='flex-end'>
        <Button onClick={handleCreateNewCode}>Create new code</Button>
      </HStack>
    </Container>
  )
}

export default SquareDeviceCodes