import { useStore } from '../states/useStore'

import InstoreChkoutButton from './InStoreChkoutButton'
import { Input, Badge, Box, Container, Flex, HStack, Spacer, VStack } from '@chakra-ui/react'

import Item from './Item'
import { itemNoText } from '../states/cartActions'
import { debouncedCustomerNameUpdate } from '../states/customerActions'

const Items = () => {
  const cart = useStore(state => state.cart)

  return (
    <Flex direction='column' w='full' pt='2'>
      <Flex alignItems='center'>
        {cart && cart.length > 0 && <Badge color='brand.700'>Cart</Badge>}
        <Spacer />
        <Box mr='2' fontSize='sm' fontWeight='500' color='brand.600'>
          {itemNoText(cart)}
        </Box>
      </Flex>
      {cart.map(item => <Item item={item} key={item.itemId} />)}
    </Flex>
  )
}

const Customer = () => {
  const name = useStore(state => state.name)

  return (
    <HStack w='full'>
      <Badge color='brand.700'>Customer name:</Badge>
      <Input onKeyUp={e => debouncedCustomerNameUpdate(e.target.value)} defaultValue={name} placeholder='Name' size='sm' />
    </HStack>
  )
}

const InstoreCart = () => {
  return (
    <Container p='1' maxW='90ch' mt='2'>
      <VStack w='full' spacing={3} p='10' pt='2'>
        <Customer />
        <InstoreChkoutButton />
        <Items />
      </VStack>
    </Container>
  )
}

export default InstoreCart