import { Badge, Box, Flex } from '@chakra-ui/react'

import { cartCopyItem, cartDecQty, cartIncQty, cartRemItem } from '../states/cartActions'
import ItemOptions from './ItemOptions'
import { SimpleButton } from '../common'


const Item = ({ item }) => {
  const { itemId, price, qty, name, options } = item
  return (
    <Flex direction='column'
      w='full'
      my='3'
      p='4'
      shadow='md'
      bg='brand.100'
    >
      <Flex direction='row' justify='space-between' alignItems='center'>
        <Box textStyle='itemTitle' fontWeight='600'>{name}</Box>
        <Box>
          <Badge size='md' bg='brand.300'>
            ${price}
          </Badge>
          <Box as='span' fontSize='sm' px='1'>x</Box>
          <Badge size='sm' color='red.500'>{`${qty}`}</Badge>
        </Box>
      </Flex>
      {
        options &&
        <ItemOptions item={item}></ItemOptions>
      }
      <Flex mt='4' mr='0' justify='flex-end'>
        <SimpleButton onClick={e => cartRemItem(itemId)}>REMOVE</SimpleButton>
        <SimpleButton onClick={e => cartCopyItem(itemId)}>COPY</SimpleButton>
        <SimpleButton fontSize='1.8rem' onClick={e => cartDecQty(itemId)}>-</SimpleButton>
        <SimpleButton fontSize='1.8rem' onClick={e => cartIncQty(itemId)}>+</SimpleButton>
      </Flex>
    </Flex>
  )
}

export default Item