import { useNavigate } from "react-router-dom"
import { Badge, Box, Button, Container, Flex } from '@chakra-ui/react'
import format from 'date-fns/format'

import { calTotal, cartReorder, itemNo } from '../states/cartActions'
import { useOrdersStore } from '../states/useOrdersStore'
import { usePrefsStore } from '../states/usePrefsStore'
import { orderRem } from '../states/ordersActions'

const Order = ({ order }) => {
  const statusNames = usePrefsStore(state => state.statusNames)
  const statuses = usePrefsStore(state => state.statuses)

  const navigate = useNavigate()
  const { orderNumber, cart, status, tsPickup, locAddress, id } = order
  const reorderHandle = () => {
    cartReorder(cart)
    navigate('/cart')
  }

  return (
    <Flex fontSize='14' direction='column' w='full' my='3' p='4' shadow='md' border='1px' borderRadius='sm'>
      <Flex my='1' direction='row' justify='space-between'>
        <Badge bg='brand.200'>{orderNumber}</Badge>
        <Badge bg='brand.200'>{statusNames[status]}</Badge>
      </Flex>
      <Flex my='1' direction='row' justify='space-between'>
        <Box>Items: {itemNo(cart)}</Box>
        {/* <Box>Upsizes: {selectedExtraNo(cart)}</Box> */}
        <Box>${calTotal(cart)}</Box>
      </Flex>
      <Flex my='1' direction='row' justify='space-between'>
        <Box>Address: {locAddress}</Box>
      </Flex>
      <Flex my='1' direction='row' justify='space-between' align='center'>
        <Box>Pickup: <Badge bg='brand.200'>{tsPickup && format(tsPickup, 'dd-MM-yyyy hh:mm')}</Badge></Box>
      </Flex>
      <Flex my='1' direction='row' justify='flex-end' align='center'>
        <Box h='8' >
          {
            (status === statuses.COMPLETE || status === statuses.CANCELED) &&
            <Button
              p='3'
              fontWeight='500'
              variant='ghost'
              colorScheme='brand'
              onClick={() => orderRem(id)}
            >
              Remove
            </Button>
          }
          <Button
            p='3'
            mx='1'
            fontWeight='500'
            variant='ghost'
            colorScheme='brand'
            onClick={e => navigate(`/success/${id}`)}
          >
            Details...
          </Button>
          <Button
            p='3'
            fontWeight='500'
            variant='ghost'
            colorScheme='brand'
            onClick={reorderHandle}>
            Reorder
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}

const Orders = () => {
  const orders = useOrdersStore(state => state.orders)
  return (
    <Container mt='2'>
      <Flex direction='column' w='full'>
        {
          Object.keys(orders)
            .sort((a, b) => (orders[a].tsPickup < orders[b].tsPickup) ? -1 : 1)
            .map((oid, idx) =>
              <Order key={idx} order={orders[oid]} />
            )}
      </Flex>
    </Container>
  )
}
export default Orders