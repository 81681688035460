import {
  extendTheme,
  theme as base,
  withDefaultColorScheme,
  withDefaultVariant,
} from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const inputSelectStyles = {
  variants: {
    filled: {
      field: {
        _focus: {
          borderColor: 'brand.500',
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: 'none',
      },
    },
  },
}

const brandRing = {
  _focus: {
    ring: 2,
    ringColor: 'brand.500',
  },
}

// const brandYellow = {
//   50: '#fffde7',
//   100: '#fff9c4',
//   200: '#fff59d',
//   300: '#fff176',
//   400: '#ffee58',
//   500: '#ffeb3b',
//   600: '#fdd835',
//   700: '#fbc02d',
//   800: '#f9a825',
//   900: '#f57f17',
// }

const brandYellow = {
  50: '#FFFFF0',
  100: '#FEFCBF',
  200: '#FAF089',
  300: '#F6E05E',
  400: '#ECC94B',
  500: '#D69E2E',
  600: '#B7791F',
  700: '#975A16',
  800: '#744210',
  900: '#5F370E',
}
// const brandBlue = {
//   50: '#e3f2fd',
//   100: '#bbdefb',
//   200: '#90caf9',
//   300: '#64b5f6',
//   400: '#42a5f5',
//   500: '#2196f3',
//   600: '#1e88e5',
//   700: '#1976d2',
//   800: '#1565c0',
//   900: '#0d47a1',
// }
// const brandGreen = {
//   50: '#f5fee5',
//   100: '#e1fbb2',
//   200: '#cdf781',
//   300: '#b8ee56',
//   400: '#a2e032',
//   500: '#8ac919',
//   600: '#71ab09',
//   700: '#578602',
//   800: '#3c5e00',
//   900: '#203300',
// }
// const brandRed = {
//   50: '#FFF5F5',
//   100: '#FED7D7',
//   200: '#FEB2B2',
//   300: '#FC8181',
//   400: '#F56565',
//   500: '#E53E3E',
//   600: '#C53030',
//   700: '#9B2C2C',
//   800: '#822727',
//   900: '#63171B',
// }

// const brand = process.env.REACT_APP_PAYMENT_TYPE === 'instore' ?
//   brandBlue : brandYellow

const brand = brandYellow

const theme = extendTheme(
  {
    colors: { brand },
    fonts: {
      heading: `Montserrat, ${base.fonts?.heading}`,
      body: `Inter, ${base.fonts?.body}`,
      // heading: `Economica, ${base.fonts?.heading}`,
      // body: `Economica, ${base.fonts?.body}`,
    },
    components: {
      Button: {
        variants: {
          primary: (props) => ({
            ...brandRing,
            color: mode('white', 'gray.800')(props),
            backgroundColor: mode('brand.500', 'brand.200')(props),

            _hover: {
              backgroundColor: mode('brand.600', 'brand.300')(props),
            },

            _active: {
              backgroundColor: mode('brand.700', 'brand.400')(props),
            },
          }),
        },
      },
      Input: { ...inputSelectStyles },
      Select: { ...inputSelectStyles },
      Checkbox: {
        baseStyle: {
          control: {
            borderRadius: 'none',
            ...brandRing,
          },
        },
      },
    },
    textStyles: {
      prodName: {
        fontSize: '0.85rem',
        fontWeight: '500',
        lineHeight: '110%',
        color: 'gray.900'
      },
      prodPrice: {
        fontSize: '0.8rem',
        color: 'gray.600'
      },
      optionType: {
        color: 'red.500',
        fontSize: '12px',
        fontWeight: '500'
      },
      orderTitle: {
        fontSize: 'md',
        fontWeight: 'semibold'
      },
      sectionTitle: {
        color: 'brand.700',
        fontSize: 'md',
        fontWeight: '600'
      },
      normalText: {
        font: 'md'
      },
      normalTextBlue: {
        font: 'md',
        color: 'blue'
      },
      itemTitle: {
        fontSize: 'sm',
        fontWeight: '500'
      },
      extraTitle: {
        color: 'gray.600',
        fontSize: 'md',
        fontWeight: '500',
      }
    },
  },
  withDefaultColorScheme({
    colorScheme: 'brand',
    components: ['Checkbox', 'Input'],
  }),
  withDefaultVariant({
    variant: 'filled',
    components: ['Input', 'Select'],
  })
)

export default theme
