import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { HashRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ChakraProvider } from '@chakra-ui/provider'
import 'focus-visible/dist/focus-visible'
import { Global, css } from '@emotion/react'

import theme from './styles/theme'
import '../src/styles/styles.css'

import App from './App'
import { AuthProvider } from './services/AuthProvider'

import { ReactQueryDevtoolsPanel } from 'react-query/devtools'
import { SquareProvider } from './services/SquareProvider'

const queryClient = new QueryClient()
const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
     outline: none;
     box-shadow: none;
   }
`

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <AuthProvider>
        <SquareProvider>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider resetCSS theme={theme}>
              <Global styles={GlobalStyles} />
              <App />
            </ChakraProvider>
            <ReactQueryDevtoolsPanel />
          </QueryClientProvider>
        </SquareProvider>
      </AuthProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
