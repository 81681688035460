import produce from 'immer'
import create from 'zustand'
import { persist, devtools } from "zustand/middleware"

// create data slice
const fn = (set, get) => ({
  versions: {
    appPrevVer: '',
    appVersion: '',
    dataPrevVer: ',',
    dataVersion: ''
  }
})

export const useVersionsStore = create(devtools(persist(fn, { name: "versions" })))

// convenient set/get functions
export const immerSetVersions = f => useVersionsStore.setState(produce(f))
export const setVersions = useVersionsStore.setState
export const getVersions = useVersionsStore.getState
