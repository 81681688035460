import { VStack, Container, Accordion, AccordionItem, AccordionButton, AccordionPanel, SimpleGrid, Box, HStack, Text, Spacer, Image } from '@chakra-ui/react'

import { usePrefsStore } from '../states/usePrefsStore'
import { qtyInCart, isInCart } from '../states/cartActions'

import { useStore } from '../states/useStore'
import Product from './Product'
import { useMediaQuery } from 'react-responsive'
import { breakpoint, listColumnsLargeScreen } from '../configs/settings'

const Products = ({ products }) => {
  const cart = useStore(state => state.cart)
  const checkIfInCart = pid => isInCart(cart, pid)
  const qtyCalculator = pid => qtyInCart(cart, pid)
  const isSmall = useMediaQuery({ maxWidth: breakpoint - 1 })

  if (isSmall) {
    return (
      <VStack w='full' spacing='4' px='4'>
        {products.map((prod, idx) =>
          <Product
            key={idx}
            selected={checkIfInCart(prod.productId)}
            qty={qtyCalculator(prod.productId)}
            product={prod} />
        )}
      </VStack>)
  }
  return (
    <SimpleGrid w='full' columns={listColumnsLargeScreen} columnGap={3} rowGap={2}>
      {products.map((prod, idx) =>
        <Product
          key={idx}
          selected={checkIfInCart(prod.productId)}
          qty={qtyCalculator(prod.productId)}
          product={prod} />
      )}
    </SimpleGrid>
  )
}

const CatStyles = {
  borderRadius: 'md',
  textSize: '1.5rem',
  boxHeight: '60px'
}

const Category = ({ category }) => {
  const { key, name } = category

  const imageSrc = require(`../assets/${key}.png`)

  return (
    <HStack
      w='full'
      pl='4'
      fontFamily='Montserrat'
      color='brand.100'
      bgGradient='linear(to-r, black, gray)'
      borderRadius={CatStyles.borderRadius}
    >
      <Text textAlign='left' fontSize={CatStyles.textSize}>{name}</Text>
      <Spacer />
      <Box h={CatStyles.boxHeight} overflowY='hidden'>
        <Image h={CatStyles.boxHeight} w='auto' src={imageSrc} />
      </Box>
    </HStack>)
}

const InstoreListMenu = ({ categories }) => {
  return (
    <Accordion w='full' allowToggle>
      {Object.keys(categories).map(key => {
        const cat = categories[key]
        return (
          <AccordionItem key={key}>
            <AccordionButton>
              <Category catKey={key} category={cat} />
            </AccordionButton>
            <AccordionPanel>
              <Products products={cat.ps} />
            </AccordionPanel>
          </AccordionItem>)
      })
      }
    </Accordion>
  )
}

const ListStyle = { padding: '10' }

const InstoreList = () => {
  const categories = usePrefsStore(state => state.catalog.categories)

  return (
    <Container w='100%' p='1' maxW='90ch' mt='2' >
      <VStack w='full' spacing={3} p={ListStyle.padding} pt='2'>
        <InstoreListMenu categories={categories} />
      </VStack>
    </Container>
  )
}
export default InstoreList
