import { useToast, Center, HStack, Spinner, Text } from '@chakra-ui/react'
import { calTotal } from '../states/cartActions'
import { useNavigate } from 'react-router-dom'

import { useStore } from '../states/useStore'
import { constructSquareOrder } from '../states/ordersActions'
import { useCreateTerminalCheckout } from '../states/useRemoteStates'
import { useState } from 'react'
import useSquare from '../services/useSquare'

const warningToast = toast => message => {
  toast({
    title: message,
    status: 'warning',
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })
}

const ChkoutButtonInPerson = () => {
  const toast = useToast()
  const warning = warningToast(toast)

  const navigate = useNavigate()
  const cart = useStore(state => state.cart)
  const { square } = useSquare()
  const [submit, setSubmit] = useState(false)

  const terminalCheckout = useCreateTerminalCheckout()

  console.log('ChkoutButtonInPerson square', square)

  const handleChkout = e => {
    if (cart.length === 0) return

    if (submit) return
    setSubmit(true)
    toast.closeAll()

    // preliminary validate and construct order data
    const order = constructSquareOrder(square)
    if (order instanceof Error) {
      setSubmit(false)
      warning(order.message)
      return
    }

    // post order to server
    terminalCheckout.mutate(order, {
      onError: error => {
        warning('Could not connect to server. Please try again later or ask our staff!')
        console.log(error.message)
      }, // to be send error message
      onSuccess: () => {
        navigate('/checkout')
      },
      onSettled: () => {
        setSubmit(false)
      }
    })
  }

  return (
    <Center cursor='pointer' borderRadius='5' fontWeight='600' color='white' h='60px' w='full' p='2'
      bg={submit || cart.length === 0 ? 'gray' : 'red.700'}
      onClick={handleChkout}
    >
      <HStack>
        {submit && <Spinner />}
        <Text fontSize='1.2rem'>{submit ? 'Checking... ' : `Checkout $${calTotal(cart)}`} </Text>
      </HStack>
    </Center>
  )

}

export default ChkoutButtonInPerson