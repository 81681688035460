import { Box, Text } from '@chakra-ui/react'
import { itemNo } from '../states/cartActions'
import { useStore } from '../states/useStore'
import { Link } from 'react-router-dom'
import { IconCart } from '../common'

const ItemNo = ({ cart }) => {
  return <Text
    color='gray.100'
    fontSize='0.95rem' fontWeight='600'
    px='10px' py='4px'
    position='absolute' bottom='0' right='0'
  >
    {itemNo(cart)}
  </Text>

}
const ChkoutLinkWithNoButton = () => {
  const cart = useStore(state => state.cart)
  return (
    <Link to='cart'>
      <Box position='relative'>
        <IconCart />
        <ItemNo cart={cart} />
      </Box>
    </Link>
  )
}

export default ChkoutLinkWithNoButton
