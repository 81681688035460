import { useMediaQuery } from 'react-responsive'
import { matchRoutes, Navigate, useLocation } from 'react-router-dom'

import List from '../components/List'
import Cancel from '../components/Cancel'
import Success from '../components/Success'
import Tracking from '../components/Tracking'

import Product from '../components/Product'
import Orders from '../components/Orders'
import CatDesc from '../components/CatDesc'

import Cart from '../components/Cart'
import InstoreCart from '../components/InstoreCart'

import NotFound from '../components/NotFound'
import Login from '../components/Login'
import Signup from '../components/Signup'

import { breakpoint } from '../configs/settings'
import { loginPath, landingPath } from '../configs/configs'
import SquareDeviceCodes from '../components/SquareDeviceCodes'
import InstoreCheckout from '../components/InStoreCheckout'
import InstoreList from '../components/InstoreList'

const instore = process.env.REACT_APP_PAYMENT_TYPE === 'instore'
console.log('instore', instore)

const onlineRoutes = [
  {
    path: '/',
    element: List,
    responsive: [List, Cart]
  },
  {
    path: '/cart',
    element: Cart,
    responsive: [List, Cart]
  },
  {
    path: '/cancel',
    element: Cancel,
    responsive: [Cancel, Cart]
  },
  {
    path: '/success',
    element: Success,
    responsive: [Success, Cart]
  },
  {
    path: '/success/:orderId',
    element: Success,
    responsive: [Success, Cart]
  },
  {
    path: '/tracking/:orderId',
    element: Tracking,
    responsive: [Tracking, Cart]
  },
  {
    path: "/product",
    element: Product,
    responsive: [List, Product]
  },
  {
    path: "/orders",
    element: Orders,
    responsive: [Orders, Cart]
  },
  {
    path: "/category",
    element: CatDesc,
    responsive: [List, CatDesc]
  },
  {
    path: "*",
    element: NotFound
  }
]

const instoreRoutes = [
  {
    path: '/signup',
    element: Signup,
    responsive: [Signup],
    public: true
  },
  {
    path: loginPath,
    element: Login,
    responsive: [Login],
    public: true
  },
  {
    path: landingPath,
    element: SquareDeviceCodes,
    responsive: [SquareDeviceCodes]
  },
  {
    path: '/',
    element: InstoreList,
    responsive: [InstoreList]
  },
  {
    path: '/cart',
    element: InstoreCart,
    responsive: [InstoreCart]
  },
  {
    path: '/checkout',
    element: InstoreCheckout,
    responsive: [InstoreCheckout]
  },
  {
    path: "/product",
    element: Product,
    responsive: [Product]
  },
  {
    path: "/orders",
    element: Orders,
    responsive: [Orders]
  },
  {
    path: "/category",
    element: CatDesc,
    responsive: [CatDesc]
  },
  {
    path: "*",
    element: NotFound
  }
]

const routes = instore ? instoreRoutes : onlineRoutes

const useResponsiveRoute = (isAuthed) => {
  const isSmall = useMediaQuery({ maxWidth: breakpoint - 1 })
  const location = useLocation()
  const matches = matchRoutes(routes, location)

  if (!matches || matches.length === 0) {
    return null
  }

  const route = matches[0].route

  // HOANG 2022 07 16: add login logic
  // if not authenticated, show Sign in page
  if (instore) {
    const pathname = matches[0].pathname
    if (!route.public && !isAuthed && pathname !== loginPath) {
      return <Navigate to={loginPath} state={{ from: location }} replace />
    }
  }

  // small layout
  if (isSmall) {
    const TheComponent = route.element
    return <TheComponent />
  }

  // big layout
  return (
    <>
      {route.responsive.map((c, idx) => {
        const TheComponent = c
        return <TheComponent key={idx} />
      })}
    </>
  )
}

export default useResponsiveRoute