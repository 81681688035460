import { v4 as uuidv4 } from 'uuid'
import { get } from './useStore'
import { getLocation } from './prefsActions'
import { isValidPickupDateTimeForLocation } from './pickupActions'
import { ErrorMessages } from '../configs/errorMessages'
// import { isEmailFormatValid } from './customerActions'
import { getOrders, immerSetOrders } from './useOrdersStore'
import { roundUpMins } from '../common/utils'
import { calTotal, itemNoText } from './cartActions'
import { SQUARE_STATUSES } from '../configs/squareStatuses'
import { format, getTime } from 'date-fns'

import { randomUppercaseChar, randomDecimalChar } from '../common/utils'

export const constructOrder = () => {
  const { name, phone, locId, tsPickup, cart } = get()

  // cart
  if (cart.length === 0) {
    return new Error(ErrorMessages.CartEmpty)
  }
  // customer
  if (name.length === 0) {
    return new Error(ErrorMessages.NameEmpty)
  }
  if (phone.length === 0) {
    return new Error(ErrorMessages.PhoneEmpty)
  }
  // HOANG 15 06 2022: drop email requirement
  // if (email.length === 0) {
  //   return new Error(ErrorMessages.EmailEmpty)
  // }
  // if (!isEmailFormatValid(email)) {
  //   return new Error(ErrorMessages.EmailFormatIncorrect)
  // }

  // location
  if (locId.length === 0) {
    return new Error(ErrorMessages.SelectLocation)
  }
  // pickup time
  if (!tsPickup) {
    return new Error(ErrorMessages.SelectPickupTime)
  }

  const checkPickupTime = isValidPickupDateTimeForLocation(tsPickup, getLocation(locId))
  if (checkPickupTime instanceof Error) {
    return checkPickupTime
  }

  const tsNow = Date.now() // timestamp

  const location = getLocation(locId)
  // HOANG 15 06 2022: default customer email using location email
  const customer = { email: location.email, phone, name }

  // HOANG 22 06 29: randomChar
  // const orderNumber = `${Math.round(tsNow / 1000)}-${random4digits()}`
  const random4Chars = `${randomUppercaseChar()}${randomUppercaseChar()}${randomDecimalChar()}${randomDecimalChar()}`
  const orderNumber = `${locId}-${format(tsNow, 'LLddHHmmss')}-${random4Chars}`

  const id = uuidv4()

  const successUrl = `${window.location.origin}/#/success/${id}?isCheckout=true`
  const cancelUrl = `${window.location.origin}/#/cart`

  return {
    id, orderNumber,
    customer, location, cart,
    successUrl, cancelUrl,
    tsPickup,
    tsCreated: tsNow,
    tsUpdated: tsNow
  }
}

export const orderAdd = order => { immerSetOrders(state => { state.orders[order.id] = order }) }

export const orderRem = orderId => { immerSetOrders(state => { state.orders[orderId] = null }) }

export const orderById = orderId => (getOrders().orders)[orderId]

export const orderStatusUpdate = ({ id, tsUpdated, status }) => {
  immerSetOrders(state => {
    const order = state.orders[id]
    if (order) { //HOANG 2022 06 29 should check because there're cases the order hasn't been added before
      order.tsUpdated = tsUpdated
      order.status = status
    }
  })
}


// SQUARE orders
export const constructSquareOrder = (square) => {
  const { name, cart } = get()
  const { squareDeviceId, squareCounterName, locId } = square
  // customer
  if (!name || name.length === 0) {
    return new Error(ErrorMessages.NameEmpty)
  }
  // cart
  if (cart.length === 0) {
    return new Error(ErrorMessages.CartEmpty)
  }
  // location
  if (!locId || locId.length === 0) {
    return new Error(ErrorMessages.SelectLocation)
  }

  const location = getLocation(locId)

  // limit name length by 200 chars
  const customer = { name: name.substring(0, 199) }

  const tsNow = Date.now() // timestamp

  // preset tsPickup for in-store checkout
  // round up 5 minutes and check if it valid
  const tsRoundUp5Mins = getTime(roundUpMins(tsNow))

  // For testing conventiency, bypass pickup time validation
  // const tsPickup = process.env.NODE_ENV === 'production' ?
  //   isValidPickupDateTimeForLocation(tsRoundUp5Mins, location)
  //   : tsRoundUp5Mins // for testing if not in production

  // const tsPickup = isValidPickupDateTimeForLocation(tsRoundUp5Mins, location)
  // if (tsPickup instanceof Error) {
  //   return new Error('Out of working hours')
  // }

  // No restriction on pickup time for instore order
  const tsPickup = tsRoundUp5Mins

  // HOANG 22 06 28: randomChar
  // const orderNumber = `${Math.round(tsNow / 1000)}-${random4digits()}`
  const random4Chars = `${randomUppercaseChar()}${randomUppercaseChar()}${randomDecimalChar()}${randomDecimalChar()}`
  const orderNumber = `${locId}-${format(tsNow, 'LLddHHmmss')}-${random4Chars}`

  const id = uuidv4()
  // const successUrl = `${window.location.origin}/#/success/${id}?isCheckout=true`
  // const cancelUrl = `${window.location.origin}/#/cart`

  return {
    id, orderNumber,
    customer,
    locId, location, cart,
    // successUrl, cancelUrl,
    tsPickup,
    tsCreated: tsNow,
    tsUpdated: tsNow,
    itemNoText: itemNoText(cart),
    squareAmount: 100 * calTotal(cart),
    squareReferenceId: orderNumber,
    squareDeviceId, // this prop should already have set in auth while pairing success
    squareCounterName, // this prop should already set in auth while pairing success
    squareLocationId: location.squareLocationId,
    squareCheckoutStatus: SQUARE_STATUSES.NONE.key, // to be set in checkout process
    squareCheckoutId: null, // to be set in checkout process
    squareUpdatedAt: null // to be set in checkout process
  }
}

