import { useContext, useDebugValue } from "react"
import SquareContext from "./SquareProvider"

const useSquare = () => {
    const { square } = useContext(SquareContext)
    useDebugValue(square, square => square?.squareCheckoutId ? "Checking out" : "Idle")
    return useContext(SquareContext)
}

export default useSquare
