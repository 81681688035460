import produce from 'immer'
import create from 'zustand'
import { persist, devtools } from "zustand/middleware"

// create data slice
const fn = (set, get) => ({
  catalog: {
    fetched: false,
    categories: {},
    products: [],
    extras: []
  },
  locations: [],
  statuses: {},
  statusNames: {},
  statusColors: {
    0: 'black', // INTENT
    1: 'grey.500', // CANCELED
    40: 'red', // NEW
    30: 'blue', // MAKING
    20: 'green', // READY
    10: 'grey.800' // PICKED
  },
  settings: {
    home: 'www.portroll.com.au',
    theme: 'LIGHT'
  },
  search: ''
})

export const usePrefsStore = create(devtools(persist(fn, { name: "prefs" })))

// convenient set/get functions
export const immerSetPrefs = f => usePrefsStore.setState(produce(f))
export const setPrefs = usePrefsStore.setState
export const getPrefs = usePrefsStore.getState
