import { useEffect } from 'react'
import Header from './components/Header'
import useResponsiveRoute from './routes/useResponsiveRoute'
import useAuth from './services/useAuth'

import { fetchVersion } from './states/prefsActions'

const style = {
  display: 'flex',
  justifyContent: 'space-between'
}

function App() {
  const { auth } = useAuth()
  const element = useResponsiveRoute(auth && auth.accessToken)

  useEffect(() => { fetchVersion() }, [])

  return (
    <div>
      <Header />
      <div style={style}>{element}</div>
    </div>
  )
}

export default App
