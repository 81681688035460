import { createContext, useState } from "react"

const SquareContext = createContext({})

export const SquareProvider = ({ children }) => {
    const [square, setSquare] = useState({})
    const resetSquare = () => setSquare(prev => ({...prev, squareCheckoutId: null, squareCheckoutStatus: null}))

    return (
        <SquareContext.Provider value={{ square, setSquare, resetSquare }}>
            {children}
        </SquareContext.Provider>
    )
}

export default SquareContext

/* setAuth
useLogin/useRefreshToken    
     accessToken, refreshToken, exp, name, email, location, role

select Device Code
    squareDeviceId, squareCounterName

useCreateTerminalCheckout
    squareCheckoutStatus, squareCheckoutId

useTerminalCheckoutStatus
    squareCheckoutStatus
*/