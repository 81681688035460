import produce from 'immer'
import create from 'zustand'
import { persist, devtools } from "zustand/middleware"

const fn = (set, get) => ({
  email: '',
  phone: '',
  name: '',
  locId: '',
  cart: [], // array of items
  tsPickup: null,
  isPaying: false
})

// const useStore = create(persist(fn, options))
export const useStore = create(devtools(persist(fn, { name: 'chkout' })))

// convenient set/get functions
export const immerSet = f => useStore.setState(produce(f))
export const set = useStore.setState
export const get = useStore.getState

