export const SQUARE_STATUSES = {
  NONE: { key: 'NONE', color: 'gray', text: 'Idle...', button: '' },
  PENDING: {
    key: 'PENDING',
    color: 'gray',
    text: 'Creating checkout... \n\rPlease wait',
    button: 'Pending'
  },
  IN_PROGRESS: {
    key: 'IN_PROGRESS',
    color: 'blue',
    text: 'Waiting for card payment ...\n\rPlease tap your card on square terminal',
    button: 'In progress'
  },
  COMPLETED: {
    key: 'COMPLETED',
    color: 'green',
    text: 'Please note down your order number for reference \r\nor scan the QRCODE for details',
    button: 'Tap here to finish'
  },
  CANCELED: {
    key: 'CANCELED',
    color: 'red',
    text: 'Canceled!\n\rPlease tap OK for next order',
    button: 'Tap here to exit.'
  },
  CANCEL_REQUESTED: {
    key: 'CANCEL_REQUESTED',
    color: 'red',
    text: 'Request cancel...\n\rPlease wait',
    button: 'Canceling'
  }
}

