import QRCode from 'qrcode.react'

import { Badge, Box, Button, Center, Container, Flex, HStack, Text, Textarea, VStack } from '@chakra-ui/react'

import { Spinner } from "@chakra-ui/spinner"
import { useNavigate } from 'react-router-dom'
import { SQUARE_STATUSES } from '../configs/squareStatuses'
import { useTerminalCheckoutStatus } from '../states/useRemoteStates'
import useSquare from '../services/useSquare'
import { IconCheck } from '../common'
import { cartReset } from '../states/cartActions'
import { customerNameUpdate } from '../states/customerActions'

const DISABLE_STATUSES = ['PENDING', 'IN_PROGRESS', 'CANCEL_REQUESTED']

const DigitalReceipt = ({ orderId, amount, orderNumber, itemNoText }) => {
  return (
    <Flex m='2' w='full' px='1' direction='column' alignItems='center'>
      <Box m='2' fontSize='1.5rem' fontWeight='600'>DIGITAL RECEIPT</Box>
      <QRCode value={JSON.stringify(`${process.env.REACT_APP_TRACKING_URL}/#/tracking/${orderId}`)} />
      <HStack justifyContent='space-between'>
        <Badge fontSize='1rem'>{`${amount}`}</Badge>
        <Badge fontSize='1rem'>{`${itemNoText}`}</Badge>
      </HStack>
      <Text color='green.600' fontSize='1rem'>Your order number:</Text>
      <Badge fontSize='2rem'>{`${orderNumber.substr(-4)}`}</Badge>
    </Flex>
  )
}

const InstoreCheckout = () => {
  const navigate = useNavigate()
  const { resetSquare } = useSquare()

  const { data } = useTerminalCheckoutStatus()
  if (!data) return null

  const { id, itemNoText, squareCheckoutStatus, squareAmount, squareReferenceId } = data
  const squareStatus = SQUARE_STATUSES[squareCheckoutStatus]
  console.log('squareStatus, squareAmount, squareReferenceId',
    squareStatus, squareAmount, squareReferenceId)

  const amount = `$${Number.parseFloat(squareAmount / 100).toFixed(2)}`
  console.log('amount', amount)

  const onClick = () => {
    resetSquare()

    if (squareStatus.key === 'COMPLETED') {
      cartReset()
      customerNameUpdate('') // reset name for in-store success checkout
      navigate('/')
      return
    }

    navigate('/cart')
  }

  return (
    <Container mt='2'>
      <Center borderRadius='5' w='full' p='2'>
        <VStack w='full' alignItems='center' justifyContent='space-between'>
          {squareStatus.key === 'COMPLETED' ? <IconCheck /> : <Spinner color={squareStatus.color} size='md' speed="2s" />}
          {squareStatus.key === 'COMPLETED' && <DigitalReceipt orderId={id} amount={amount} orderNumber={squareReferenceId} itemNoText={itemNoText} />}
          <Textarea
            pt='2'
            lineHeight={"90%"}
            size='lg'
            textAlign='center'
            border='none'
            isReadOnly={true}
            color={squareStatus.color}
            value={squareStatus.text} />
          <Button
            w='160px'
            colorScheme="green"
            variant='solid'
            disabled={DISABLE_STATUSES.includes(squareStatus.key)}
            onClick={onClick}>
            {squareStatus.button}
          </Button>
        </VStack>
      </Center>
    </Container>
  )
}

export default InstoreCheckout
