import produce from 'immer'
import create from 'zustand'
import { persist, devtools } from "zustand/middleware"

const fn = (set, get) => ({
  orders: {}
})

// const useStore = create(persist(fn, options))
export const useOrdersStore = create(devtools(persist(fn, { name: 'orders' })))

// convenient set/get functions
export const immerSetOrders = f => useOrdersStore.setState(produce(f))
export const setOrders = useOrdersStore.setState
export const getOrders = useOrdersStore.getState

