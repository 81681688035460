import { createContext, useState } from "react"

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({})

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext

/* setAuth
useLogin/useRefreshToken    
     accessToken, refreshToken, exp, name, email, location, role

select Device Code
    squareDeviceId, squareCounterName

useCreateTerminalCheckout
    squareCheckoutStatus, squareCheckoutId

useTerminalCheckoutStatus
    squareCheckoutStatus
*/