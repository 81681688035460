import React, { useState } from 'react'

import { Box, Flex, Radio, Checkbox, SlideFade } from '@chakra-ui/react'

import { cartChangeOptions, selectedStdOptions } from '../states/cartActions'
import { optionArrayToText } from '../common/utils'

const checkShownEnable = (check) => {
  return {
    shown: check.std || !!check.exclusive,
    enable: check.std ? check.enable : true
  }
  // return { shown: false, enable: false }
}

const isAnyStdEnable = selected => {
  return !!Object.keys(selected).find(type => {
    const opts = selected[type]
    return !!opts.find(opt => opt.std && opt.enable)
  })
}

// if no standard option enabled, don't show the options group
const groupShown = selected => selected.reduce((acc, c) => acc || c.std, false)

const selectedOptionsToggle = (options, type, idx) => {
  const toggleOptionAtIdx = (arr, idx) =>
    arr.map((opt, index) => (index !== idx ? opt : { ...opt, checked: !opt.checked }))
  return { ...options, [type]: toggleOptionAtIdx(options[type], idx) }
}

const selectedOptionsExclusive = (options, type, idx) => {
  const resultArray = options[type].map((opt, index) => {
    if (index === idx) return { ...opt, checked: true }
    if (opt.checked) return { ...opt, checked: false }
    return opt
  })
  return { ...options, [type]: resultArray }
}

const Bar = ({ item }) => {
  const { itemId } = item
  const changeOptions = cartChangeOptions(itemId)
  const handleStd = () => changeOptions(selectedStdOptions(item.options))

  return (
    <Flex justify='flex-end'>
      <Box
        mt='2'
        px='1'
        borderTop='1px'
        fontSize='sm'
        fontWeight='500'
        color='blue'
        cursor='pointer'
        onClick={handleStd}>Reset to standard options
      </Box>
    </Flex>
  )
}

const OptionsStrip = ({ item, type }) => {
  const { selectedOptions, itemId } = item
  const checks = selectedOptions[type]

  const handleCheckbox = idx => e => {
    const selectedChange = selectedOptionsToggle(selectedOptions, type, idx)
    cartChangeOptions(itemId)(selectedChange)
  }

  const handleOptionbox = (idx, checked) => e => {
    if (checked) return null

    const selectedChange = selectedOptionsExclusive(selectedOptions, type, idx)
    cartChangeOptions(itemId)(selectedChange)
  }

  return (
    <Flex wrap='wrap' w='full'>
      {checks.map((c, idx) => {
        const variants = checkShownEnable(c)
        return (
          <Flex key={idx} display={variants.shown ? 'flex' : 'none'} my='3'>
            {c.exclusive ? (
              <Radio
                isDisabled={!variants.enable}
                isChecked={c.checked}
                onChange={handleOptionbox(idx, c.checked)}
                colorScheme='blue'
              />
            ) : (
              <Checkbox
                isDisabled={!variants.enable}
                isChecked={c.checked}
                onChange={handleCheckbox(idx)}
                colorScheme='blue'
              />
            )}
            <Box isTruncated fontSize='md' mx='2'>{c.label}</Box>
          </Flex>
        )
      })}
    </Flex>
  )
}

const Group = ({ item, type }) => {
  return (
    <Flex direction='column'>
      <Box mt='4' textStyle='optionType'>{type}</Box>
      <OptionsStrip item={item} type={type} />
    </Flex>
  )
}

const Selector = ({ item }) => {
  return (
    <Box
      my='2'
      px='4'
      pb='3'
      bg='gray.50'
      rounded='md'
      shadow='md'>
      <Flex direction='column' justify='flex-start'>
        {Object.keys(item.selectedOptions)
          .sort()
          .map((type, idx) => {
            const shown = groupShown(item.selectedOptions[type])
            return shown && <Group key={idx} item={item} type={type} />
          }
          )}
      </Flex>
      {isAnyStdEnable(item.selectedOptions) && <Bar item={item} />}
    </Box>
  )
}

const SelectedToText = ({ selected }) => {
  const toText = selected =>
    Object.keys(selected)
      .sort()
      .reduce((acc, type) => acc + `<span>${type}</span>: ${optionArrayToText(selected[type])}   `, '')

  return <Box
    sx={{
      '> span': {
        margin: '0 0 0 0.5rem',
        color: 'red.500',
        fontSize: '12px',
        fontWeight: '500'
      }
    }}
    fontSize='sm'
    isTruncated
    dangerouslySetInnerHTML={{ __html: toText(selected) }}
  />
}

const Selected = ({ selected, handler, show }) => {
  return (
    <Flex justify='space-between' cursor='pointer' onClick={handler} >
      <SelectedToText selected={selected} />
      {
        isAnyStdEnable(selected) &&
        <Box
          fontSize='sm'
          fontWeight='500'
          color='blue'
          variant='ghost'
          ml='3'
        >
          {show ? 'Done' : 'Change'}
        </Box>}
    </Flex>
  )
}

const ItemOptions = ({ item }) => {
  const [show, setShow] = useState(false)
  const handler = e => setShow(!show)

  return (
    <Box my='3'>
      <Selected show={show} selected={item.selectedOptions} handler={handler}></Selected>
      {show &&
        <SlideFade in={show} offsetY='-100px'>
          <Selector item={item}></Selector>
        </SlideFade>}
    </Box>

  )
}

export default ItemOptions