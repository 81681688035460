import { SlideFade, Spacer, Text, Container, Flex, HStack } from '@chakra-ui/react'
import { cartAddItem } from '../states/cartActions'
import { IconAddToCart, BadgeQty, Card, BadgeInCart } from '../common'
import ChkoutLinkButton from './ChkoutLinkButton'

const Product = ({ selected, qty, product }) => {
  const addItemHandle = selected => () => {
    if (!selected) {
      cartAddItem(product)
    }
  }

  return (
    <Card selected={selected} onClick={addItemHandle(selected)}>
      <Flex w='100%' alignItems='center'>
        <Container px='0'><Text textStyle='itemTitle' w='auto' isTruncated>{product.name}</Text></Container>
      </Flex>
      <Flex w='100%' alignItems='center'>
        <Text mr='1' textStyle='prodPrice'>${product.price}</Text>
        <Spacer />
        {selected ?
          <SlideFade in={selected} offsetY='20px'>
            <HStack>
              <BadgeQty>{qty}</BadgeQty>
              <BadgeInCart> in cart</BadgeInCart>
              <ChkoutLinkButton />
            </HStack>
          </SlideFade>
          :
          <IconAddToCart />}
      </Flex>
    </Card>
  )
}

export default Product