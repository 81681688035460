// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the

import { addMinutes, getTime, roundToNearestMinutes } from 'date-fns'

// leading edge, instead of the trailing.
export const debounce = (func, wait = 200, immediate = false) => {
  let timeout
  return function (...args) {
    var later = function () {
      timeout = null
      if (!immediate) func(...args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func(...args)
  }
}

export const random3digits = () => Math.floor(Math.random() * (999 - 100 + 1) + 100)
export const random4digits = () => Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)

const UPPER_CASE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' // 26
const DECIMALS_CHARS = '0123456789' // 10

const randomChar = (source) => source.charAt(Math.floor(Math.random() * source.length))

export const randomUppercaseChar = () => randomChar(UPPER_CASE_CHARS)
export const randomDecimalChar = () => randomChar(DECIMALS_CHARS)

// to flatten selected to text
export const optionArrayToText = optionArr => {
  const text = optionArr
    .filter(opt => opt.checked)
    .map(opt => opt.label)
    .join(', ')
  return text.length === 0 ? 'None' : text
}

// time
const roundMins = 5
export const roundUpMins = dt => {
  const round = roundToNearestMinutes(dt, { nearestTo: roundMins })
  if (getTime(round) >= getTime(dt)) {
    return round
  }
  return addMinutes(round, 5)
}


