import { Button, Flex, useToast } from '@chakra-ui/react'
import { format, getTime, isFuture } from 'date-fns'
import { forwardRef, useEffect } from 'react'
import { StyledDatepicker } from '../common'
import { calendarMaxDate, getOpenDateTimeForDate, isOpenDayForLocation, tsPickupUpdate, tsPickupUpdateDate } from '../states/pickupActions'
import { getLocation } from '../states/prefsActions'
import { useStore } from '../states/useStore'

import { roundUpMins } from '../common/utils'

const Datepicker = props => (
  <StyledDatepicker w='full' h='8' fontSize='md' {...props} />
)

const PickerButton = forwardRef(({ value, onClick }, ref) => (
  <Button variant='outline' onClick={onClick} ref={ref}>
    {value}
  </Button>
))

const PickupTime = () => {
  const toast = useToast()

  const locId = useStore(state => state.locId)
  const location = getLocation(locId)

  const tsPickup = useStore(state => state.tsPickup)

  const [dtOpen, dtClose] = getOpenDateTimeForDate(location)(Date.now())

  const handleNow = (loc) => e => {
    const now = Date.now()

    toast.closeAll()
    if (!isOpenDayForLocation(loc)(now)) {
      toast({
        title: `${format(now, 'iiii, d/MM')}: Outlet is not open today`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
      return
    }
    if (getTime(now) < getTime(dtOpen)) {
      toast({
        title: `${format(now, 'h:mm aa')}: Outlet is not open yet`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
      return
    }
    if (getTime(now) > getTime(dtClose)) {
      toast({
        title: `${format(now, 'h:mm aa')}: Outlet is closed`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
      return
    }

    tsPickupUpdate(roundUpMins(now))
  }

  useEffect(() => {
    // preset tsPickup as now time if not defined
    if (!tsPickup) {
      tsPickupUpdate(roundUpMins(dtOpen || Date.now()))
    }
  }, [dtOpen, tsPickup])

  return (
    <Flex w='full' alignItems='center' justifyContent='space-between'>
      <Datepicker
        w='9rem'
        selected={tsPickup || roundUpMins(dtOpen || Date.now())}
        onChange={tsPickupUpdateDate}
        filterDate={isOpenDayForLocation(location)}
        minDate={Date.now()}
        maxDate={calendarMaxDate(Date.now())} // 1 + 1 months max
        dateFormat='iiii, d/MM'
        customInput={<PickerButton />}
      />
      <Datepicker
        mx='1'
        w='5rem'
        selected={tsPickup || roundUpMins(dtOpen || Date.now())}
        onChange={tsPickupUpdate}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={10}
        minTime={dtOpen}
        maxTime={dtClose}
        filterDate={isFuture}
        timeCaption="Time"
        timeFormat="HH:mm"
        dateFormat="HH:mm"
        customInput={<PickerButton />}
      />
      <Button mr='2px' ml='0' variant='link' color='blue' onClick={handleNow(location)}>Now</Button>
    </Flex>
  )
}

export default PickupTime