import { immerSet } from './useStore'
import { emailFormat } from '../configs/configs'
import { debounce } from '../common/utils'

export const customerNameUpdate = name => immerSet(state => { state.name = name })
export const customerPhoneUpdate = phone => immerSet(state => { state.phone = phone })
export const customerEmailUpdate = email => immerSet(state => { state.email = email })

export const isEmailFormatValid = (email) => {
  return String(email)
    .toLowerCase()
    .match(emailFormat)
}

export const debouncedCustomerNameUpdate = debounce(customerNameUpdate)
export const debouncedCustomerPhoneUpdate = debounce(customerPhoneUpdate)
export const debouncedCustomerEmailUpdate = debounce(customerEmailUpdate)